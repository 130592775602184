<template>
  <div class="vip">
    <van-nav-bar
      title='ەرەكشە مۇشە رايونى'
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>

    <van-divider
      v-if="vipStatus"
      :style="{ color: '#999', borderColor: '#999', padding: '0 16px' }"
      >ەرەكشە مۇشە ۋاقىتىڭىز اياقتالۋعا</van-divider
    >

    <count-down :endTime="endTime" />
    <!--
    <van-divider
      v-if="!vipStatus"
      :style="{
        color: '#ee0a24',
        borderColor: '#ee0a24',
        padding: '0 16px',
        fontSize: '16px',
      }"
      >قوس 11 ەرەكشە تيمدىلىك قيمىلى اياقتالۋعا</van-divider
    >

    <count-down v-if="!vipStatus" :endTime="vipTime" /> -->

    <div class="vip-desc" v-if="!vipStatus">
      <van-divider
        :style="{
          color: '#999',
          borderColor: '#999',
          padding: '0 16px',
          fontSize: '16px',
          marginTop: '0',
        }"
        >ەسكەرتۋ</van-divider
      >
      VIP مۇشەلىگىن ساتىپ الساڭىز بەلگىلەنگەن ۋاقىت ىشىندە باعدارلاماداعى VIP
      مازمۇنداردى تەگىن كورە الاسىز. <span class="text-red">(VIP بەلگىسى جوق مازمۇندار جەكە
      ساتىپ الىنادى)</span>
    </div>
    <div class="memberlistContainer">
      <div class="memberlist" v-for="item in members" :key="item.id">
        <h4>{{ item.title }}</h4>
        <van-icon name="gem-o" color="#ee0a24" />
        <p class="price">
          <span class="oldPrice">¥{{ item.old_price }} </span>
          <span class="currentPirce">¥{{ item.price }} </span>
        </p>
        <p class="limited_time">ۋاقىتى: {{item.limited_time}} كۇن</p>
        <van-button
          @click="buyVip(item)"
          round
          type="danger"
          size="small"
          :disabled="vipStatus"
          >ساتىپ الۋ</van-button
        >
      </div>
    </div>
    <div class="vip-tips">
      ەرەكشە مۇشە بولىنعان اقى قايتارىلمايدى. جوعارىداعى ەسكەرتۋدى جاقىسلاپ
      وقىڭىز.
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { wechatRedirect } from '@/api/user'
import {
  getMembers,
  vipStatus,
  createVipMember,
  orderPayStatus
} from '@/api/member'
import { addDate, today } from '../../utils/membertime.js'
import { setItem } from '@/utils/storage'
import CountDown from '@/components/CountDown'

export default {
  data () {
    return {
      members: [],
      vip_status: true,
      payParams: {},
      limitedDay: '',
      endTime: '',
      vipStatus: false,
      vipTime: ''
    }
  },
  mounted () {
    this.getMember()
    this.getVipStatus()
  },
  components: {
    CountDown
  },
  methods: {
    async getVipStatus () {
      const { data: res } = await vipStatus()
      setItem('vipStatus', res.status)
      const limitedTime = res.limited_time.toString().replace(/-/g, '/')
      const endTime = new Date(limitedTime).getTime() / 1000 + ' '
      this.endTime = endTime
      this.vipTime = new Date('2022/11/11 23:59:59').getTime() / 1000 + ' '
      this.vipStatus = res.status
    },
    async getMember () {
      try {
        const { data } = await getMembers()
        this.members = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async buyVip (item) {
      const { limited_time: limitedDay, price, id: memberId, title } = item
      const limitedTime = this._handleTime(limitedDay)

      try {
        await this.$dialog.confirm({
          title,
          message: `ۋاقىتى ${limitedDay} كۇن، تولەم اقىسى ${price} يۋان`,
          confirmButtonText: 'تۇراقتاندىرۋ',
          cancelButtonText: 'قالدىرۋ'
        })

        this.payParams = {
          memberName: title,
          limitedTime,
          memberId,
          price
        }
        this.limitedDay = limitedDay
        this._pay()
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 会员日期格式化
     */
    _handleTime (memberDay) {
      return addDate(today(), memberDay)
    },

    async _pay () {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      _this.payParams.openId = openId

      const { code, data } = await createVipMember(_this.payParams)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: async (res) => {
            if (res.errMsg === 'chooseWXPay:ok') {
              orderPayStatus('success', {
                ..._this.payParams,
                orderNumber: data.order_number
              })

              location.reload()
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: async (res) => {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.vip {
  padding-top: 92px;

  .colon {
    display: inline-block;
    margin: 0 14px;
    color: #ee0a24;
  }
  .block {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 28px;
    text-align: center;
    background-color: #ee0a24;
  }

  .vip-desc {
    font-size: 30px;
    direction: rtl;
    text-align: justify;
    padding: 30px 20px;
    background-color: #fff;
    margin: 20px;
  }
  .memberlistContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    direction: rtl;

    .memberlist {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 290px;
      background-color: #fff;
      border-radius: 10px;
      margin: 0 10px 20px 10px;
      box-shadow: 0 8px 12px #ebedf0;
      padding: 20px 40px 30px 20px;

      h4 {
        font-size: 32px;
        margin-bottom: 20px;
      }

      .van-icon {
        margin: 10px auto 0;
      }

      p.price {
        height: 60px;
        line-height: 60px;
        .oldPrice {
          font-size: 24px;
          color: #999;
          text-decoration: line-through;
          margin-left: 10px;
        }
        .currentPirce {
          font-size: 32px;
          color: #ee0a24;
        }
      }

      p.limited_time {
        font-size: 28px;
        color: #666;
        margin-top: 20px;
      }

      .van-button {
        width: 180px;
        height: 54px;
        font-size: 28px;
        margin-top: 30px;
      }
    }
  }

  .vip-tips {
    direction: rtl;
    font-size: 28px;
    color: #ee0a24;
    padding: 30px;
    text-align: justify;
  }
}
</style>
