/* eslint-disable no-extend-native */
// 获取当前时间
function today () {
  const getDate = new Date()
  return getDate.getFullYear() + '-' + (getDate.getMonth() + 1).toString().padStart(2, '0') + '-' + getDate.getDate().toString().padStart(2, '0') + ' ' + getDate.getHours().toString().padStart(2, '0') + ':' + getDate.getMinutes().toString().padStart(2, '0') + ':' + getDate.getSeconds().toString().padStart(2, '0')
}

/**
 * 解决ios端兼容问题的日期加天数
 */
function addDate (myDate, days) {
  function AddDays (date, value) {
    return new Date(Date.parse(date) + (86400000 * value))
  }

  Date.prototype.format = function (format) {
    var o =
    {
      'M+': this.getMonth() + 1, // month
      'd+': this.getDate(), // day
      'h+': this.getHours(), // hour
      'm+': this.getMinutes(), // minute
      's+': this.getSeconds(), // second
      'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
      S: this.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) { format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) { format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)) }
    }
    return format
  }
  var strdates = myDate
  var strdays = days
  var d = strdates.replace(/-/g, '/')
  var now = new Date(d)
  var values = AddDays(now, strdays)
  var strt = values.format('yyyy-MM-dd hh:mm:ss')
  return strt
}

module.exports = {
  addDate: addDate,
  today: today
}
